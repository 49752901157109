import { Component, OnInit,HostListener } from '@angular/core';
import { NavController, MenuController, Platform, ModalController } from '@ionic/angular';
import { CommonserviceService } from 'src/app/services/commonservice.service';
import {Config } from "../../config/config";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  userData: any;
  selectedImage: any = '../assets/4.png';
  result: any;
  show:boolean=false;
  panStatus:number=0;
  bankStatus:number=0;
  copyrights:string='';
  deviceWidth: any;
  isBrowserMenu = '2';
  profileStatus : number = 3;
  @HostListener('window:resize', ['$event']) getScreenSize(event?) {
    this.deviceWidth = window.innerWidth;
    console.log(this.deviceWidth, this.isBrowserMenu);
    if(this.conf.value == 0)
    {
      this.objModal.dismiss();
      this.conf.value = 2;
    }else if( this.conf.value == 1 || this.deviceWidth > 500){
      if(localStorage.getItem('ismenu') == '1')
      {
        this.navCtrl.navigateBack('/dashboard');
      }
      this.conf.value = 2;
    }
  }
  constructor(public commonService: CommonserviceService, public navCtrl: NavController, public menu: MenuController, public platform : Platform, 
    public objModal : ModalController,public conf:Config,public iab:InAppBrowser) { 
    this.deviceWidth = window.innerWidth;
    localStorage.setItem('ismenu','1');
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit() {
    this.menuOpened()
  }

  addCash() {
    this.dissableBackevent();
    this.navCtrl.navigateForward("/add-cash");
  }
  wallet() {
    localStorage.setItem('footer','3')
    this.dissableBackevent();
    this.navCtrl.navigateForward("/my-account")
  }
  logout() {
    this.dissableBackevent();
    this.commonService.logout();
  }
  menuOpened(){
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.commonService.commonApiService('get','profile','').then((result:any)=>{
      this.result = result;
      this.selectedImage = result.data.profile_image;
      this.panStatus = this.result.pan_status;
      this.bankStatus = this.result.bank_status;
      this.copyrights = result.copyright;
      this.profileStatus = this.result.address_proof_status;
    });
  }
  profile(){
    this.dissableBackevent();
    this.navCtrl.navigateForward('/personal-details');
  }
  ionViewDidLeave()
  {
    localStorage.setItem('ismenu','2');
  }
  
  dissableBackevent() {
   
    if (this.deviceWidth > 500 || this.conf.value == 1) {
      console.log(this.conf);
      this.navCtrl.navigateBack('/dashboard');
      // this.location.back();
    }
    else {      
      this.objModal.dismiss(); }
  }
  more()
  {
    this.dissableBackevent();
    this.navCtrl.navigateForward('/more');
  }
  moreSetting(type)
  {
    this.dissableBackevent();
    this.navCtrl.navigateForward('/moresettings?type=' + type);
  }
  settings() {
    this.dissableBackevent();
    this.navCtrl.navigateForward('/settings');
  }
  openSite()
  {
    this.iab.create('https://11players.in/responsible-policy','_system');
  }
  showMore(){
    this.show = !this.show
  }
  panRedirect(){
    this.dissableBackevent();
    this.navCtrl.navigateForward('/pan-details');
  }
  bankRedirect(){
    this.dissableBackevent();
    this.navCtrl.navigateForward('/bank-details');
  }
  verifyDetailsAddcash()
  {
    this.dissableBackevent();
    this.commonService.verifyDetails('use add cash service');
  }
}
