import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { url, aws_url,appVersion,ifscUrl,Config } from "../config/config"
import { ToastController, AlertController, LoadingController, NavController,MenuController, ModalController } from '@ionic/angular';
import { myEnterAnimation, myLeaveAnimation } from '../animations/enter';
import { MenuComponent  } from '../components/menu/menu.component'
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  objOption: any = '';
  optionsFormData: any;
  loading: any;
  response: any;
  lat : any;lng : any;
  filtered_states : any;
  constructor(public http: HttpClient, public toastController: ToastController, public loadingController: LoadingController, 
    public alertController: AlertController, public navCtrl: NavController,public modalCtrl : ModalController,
    public conf : Config,public geolocation : Geolocation,public nativeGeocoder: NativeGeocoder) {
    // this.getRemoteData();
  }
  /*
  
  puspose : api service call using get/post method
  parms : type<string>=get/post,api<string>,postdat<obj>,addHeader<boolean>
  retrun : response 
  */
  toastPreview(message) {
    this.toastController.create({
      message: message,
      cssClass : 'toastCss',
      duration: 2000
    }).then(result => result.present());
  }

  logout() {
    let data = [];
    this.commonApiService('get', 'logout', '').then((result: any) => {
      console.log(result);
      localStorage.clear();
      this.navCtrl.navigateRoot('/login');
    }, (err) => {
      console.log(err.error.error);
      this.toastPreview("Something went wrong!");
    });
  }

  commonApiService(type:string,api:string,postdata:any = '',headerData?){
    let header:any;
    // console.log(headerData);
    if (headerData == null) {
      header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('login_token'),
        'App-version':appVersion,
        'web-apk-user':'1'
      });
    } else {
      header = new HttpHeaders(headerData);
    }
    this.objOption = {
      headers: header
    }
    if (type == 'post') {
      return new Promise((resolve, reject) => {
        this.http.post(url + api, postdata, this.objOption).subscribe(response => {
          this.response=response;
          if (this.response.status == 303) { // rejected data   
            localStorage.clear();
            this.navCtrl.navigateRoot('/landing');
          } else if (this.response.status == 301) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }        
          else if (this.response.status == 302) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }
          else if (this.response.status == 200 && this.response.match_time_status == 0) {
            this.presentCommonAlert('Alert',this.response.message);
            this.navCtrl.navigateRoot('/dashboard');
          }        
          else
          {
            resolve(response);
          } 
        }, err => {
          reject(err);
          this.errorAlert();
        })
      });
    } else {
      console.log(postdata);
      if (postdata == {}) {
        postdata = '';
      }
      return new Promise((resolve, reject) => {
        this.http.get(url + api + postdata, this.objOption).subscribe((response) => {
          this.response=response;
          if (this.response.status == 303) { // rejected data   
            localStorage.clear();
            this.navCtrl.navigateRoot('/landing');
          } else if (this.response.status == 301) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }        
          else if (this.response.status == 302) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }
          else if (this.response.status == 200 && this.response.match_time_status == 0) {
            this.presentCommonAlert('Alert',this.response.message);
            this.navCtrl.navigateRoot('/dashboard');
          }       
          else
          {
            resolve(response);
          } 
        }, err => {
          reject(err);
          this.errorAlert();
        });
      });
    }

  }

  commonApiServicebank(postdata:string){
    
      return new Promise((resolve, reject) => {
        this.http.get(ifscUrl + postdata).subscribe((response) => {
          this.response=response;
          console.log( this.response)
           if (this.response.status == 301) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }        
          else if (this.response.status == 302) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }           
          else
          {
            resolve(response);
          } 
        }, err => {
          reject(err);
          if(err.status == 404){
            // this.toastPreview('Invalid IFSC Code');
          }else{
            this.errorAlert();
          }
        });
      });
  }


  commonApiServiceFormControl(call: string, postData: any) {
    let headers1 = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('login_token'),
      'App-version':appVersion,
      'web-apk-user':'1'

    });

    this.optionsFormData = {
      headers: headers1
    }
    return new Promise((resolve, reject) => {
      this.http.post(url + call, postData, this.optionsFormData).subscribe(postdata => {
        this.response=postdata;
           if (this.response.status == 303) {  
             localStorage.clear();
             this.navCtrl.navigateRoot('/landing');
           }else if (this.response.status == 301) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }        
          else if (this.response.status == 302) { // rejected data   
            this.navCtrl.navigateRoot('/maintenance?response='+JSON.stringify(this.response));
          }           
            {
            resolve(postdata);
           } 
      }, err => {
        console.log(err);
        console.log(err.error);
        this.errorAlert();
      });
    });
  }

  commonAwsApiService(api: string, postData?) {
    let setData;
    {
      console.log(api);
      return new Promise((resolve, reject) => {
        this.http.get(aws_url + api).subscribe((response) => {
        resolve(response);
        }, err => {
          reject(err);
          this.errorAlert();
        });
      });
    }

  }
  // getRemoteData(){
  //   this.http.get('https://dream11bkt.s3.ap-south-1.amazonaws.com/FinalAll/Cricket/matches.json').map(res => res ).subscribe(data => {
  //       console.log(data);
  //   });
  // }

  //common services for present toast,alert and loader

  //loading function for load preloader before or after api call
  async presentPreloader() {
    this.loading = await this.loadingController.create({
      duration: 5000,
      spinner: 'circles',
      translucent: true,
      message: 'Please wait...',
      cssClass : 'loadingCss',
      backdropDismiss: true
    });
    await this.loading.present();
  }

  //preloader dismissfunction
  async dismissPreloader() {
    await this.loading.dismiss();
  }
// error alert when 500 
async errorAlert() {
  const alert = await this.alertController.create({
    cssClass : 'alertClass',
    header: 'Alert',
    message: 'We are facing some issue while getting the details. Please contact customer care.',
    buttons: [
        {
        text: 'Okay',
        handler: () => {
          this.navCtrl.navigateBack('/dashboard');
        }
      }
    ]
  });
  await alert.present();
}
  //function for present toast message 
  async presentCommonToast(messageText) {
    const toast = await this.toastController.create({
      message: messageText,
      cssClass : 'toastCss',
      duration: 2000 //duration in milli seconds
    });
    toast.present();
  }

  //function for simple alert

  async presentCommonAlert(title, messageText) {
    const alert = await this.alertController.create({
      cssClass : 'alertClass',
      header: title,
      message: messageText,
      buttons: ['OK']
    });
    await alert.present();
  }

  //just need to follow these steps in your_page.ts file
  // * Step:1
  // import { CommonserviceService } from '../../services/commonservice.service';


  // * Step:2
  // Add service in constructor
  // public commonService: CommonserviceService

  // * Step:3
  // --for toast--
  // this.commonService.presentCommonToast('some message text');

  // --for present preloader--
  // this.commonService.presentPreloader();

  // --for dismiss preloader--
  // this.commonService.dismissPreloader();

  // --for simple alert--
  // this.commonService.presentCommonAlert('alert title','message text');



  nameValidation(name) {
    const username = /^[a-zA-Z_][a-zA-Z_ ]*[a-zA-Z_]$/;
    if (username.test(name)) {
      return true;
    } else {
      return false;
    }
  }

  emailValidation(mail) {
    const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.test(String(mail).toLocaleLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  mobileValidation(mobile) {
    const number = /^[0]?[6789]\d{9}$/;
    if (number.test(mobile)) {
      return true;
    } else {
      return false;
    }
  }

  passwordValidation(password) {
    const pass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (pass.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  withdrawValidation(wallet) {
    const number = /^[0]?[6789]\d{9}$/;
    if (number.test(wallet)) {
      return true;
    } else {
      return false;
    }
  }
 //menu page based on screensize

 async openMenu()
 {
   let width = window.innerWidth;
   if (width > 500) {
     this.navCtrl.navigateForward('/menupage');
     this.conf.value = 1;
   }
   else {
     this.conf.value = 0;
     let objModel = await this.modalCtrl.create({
      component:MenuComponent,
        animated: true,
       showBackdrop: true,
       backdropDismiss: true,
       cssClass: 'menuModalCss',
       enterAnimation: myEnterAnimation,
       leaveAnimation: myLeaveAnimation,
     });
     await objModel.present();
     let res = await objModel.onDidDismiss();
   }
 }
 /*
 * Role : get the current location (latitude and longitude)
 * params : <null> 
 * Return : nothing
 */
 getCurrentLocation(type?)
 {
  // this.getProfile();
   console.log('here');
   let options: NativeGeocoderOptions = {
     useLocale: true,
     maxResults: 5
 };
 var options1 = {
   enableHighAccuracy: true,
   timeout: 5000,
   maximumAge: 0
   };

   this.geolocation.getCurrentPosition(options1).then((resp) => {
    this.lat = resp.coords.latitude;
   this.lng = resp.coords.longitude;
   console.log(this.lat,this.lng); 
  //  let data={
  //   state_name : 'Tamil Nadu',
  //   country_code : 'IN'
  //  };
  //    this.commonApiService('post','checkAllowedLocation',data).then((result:any)=>{
  //   let result1 = result;
  //   if(result1.status != 200){
  //     this.presentCommonAlert('Alert',result1.message);
  //     localStorage.clear();
  //     this.navCtrl.navigateRoot('/login');
  //   }
  //   else if(result1.status == 200 && type == 1){
  //     this.navCtrl.navigateForward("/dashboard");
  //   }
  // });
   this.nativeGeocoder.reverseGeocode(this.lat,this.lng, options)
 .then((result: NativeGeocoderResult[]) => {
   console.log(JSON.stringify(result[0]));
   let data={
    state_name : result[0].administrativeArea,
    country_code : result[0].countryCode
   };
     this.commonApiService('post','checkAllowedLocation',data).then((result:any)=>{
    let result1 = result;
    if(result1.status != 200){
      this.presentCommonAlert('Alert',result1.message);
      localStorage.clear();
      this.navCtrl.navigateRoot('/login');
    }
    else if(result1.status == 200 && type == 1){
      this.navCtrl.navigateForward("/dashboard");
    }
  });
  //  if(result[0].countryCode != 'IN')
  //  {
  //    this.presentCommonAlert('Alert','Your location does not support 11players. It`s only available in India');
  //    localStorage.clear();
  //    this.navCtrl.navigateRoot('/login');
  //  }
  //  else if(result[0].countryCode == 'IN')
  //  {
  //    let state = result[0].administrativeArea;
  //    for(let state_name_data of this.filtered_states)
  //     {
  //       if(state_name_data.state_name == state)
  //       {
  //         this.presentCommonAlert('Alert','Your State('+state+') does not support 11players.');
  //     localStorage.clear();
  //      this.navCtrl.navigateRoot('/login');
  //       }
  //     }
  //  }
 })
 .catch((error: any) => console.log(error));
   }).catch((error) => {
     console.log('Alert','Error getting location');
   });
 }

 async verifyDetails(type) {
  const alert = await this.alertController.create({
    message: 'You cannot '+ type +' till your KYC details get verified.',
    cssClass : 'alertClass',
    buttons: [
      {
        text: 'Okay',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          this.navCtrl.navigateForward('/verify');
        }
      }
    ]
  });
  await alert.present();
}

// getProfile(){
//   let result1,state_data=[];
//   this.commonApiService('get','profile','').then((result:any)=>{
//     result1 = result;
//     if(result1.status == 200){
//       state_data = result1.all_states;
//       this.filtered_states=state_data.filter(i => i.status === 0);
//       console.log(this.filtered_states);
//     }else {
//       console.log(result.message);
//     }
//   });
// }
}
 
/**
 * Step:1
import { CommonserviceService } from '../../services/commonservice.service';


* Step:2
Add service in constructor
public commonService: CommonserviceService

* Step:3
use below code in any function
samaple for using method
post
this.commonService.CommonserviceService('post','/api/method',{name:name}).then((result:any)=>{
  if(result.status == "success"){
   sucess code
  }else if(result.status == "error"){
   error code
  }
});


get
this.commonService.CommonserviceService('get','/api/method').then((result:any)=>{
  if(result.status == "success"){
   sucess code
  }else if(result.status == "error"){
   error code
  }
});
**/