import { createAnimation  } from '@ionic/core';

/*
* Role         : Enter Animation
* Parameters   : AnimationC <Animation>, baseEl <HTMLElement>
* Return 	     : Enter Animation Effect    
*/  
export function myEnterAnimation(baseEl: HTMLElement): any  {

  let backdropAnimation = createAnimation('modalEnter_backdrop')
	.addElement(baseEl.querySelector('ion-backdrop'))
	.beforeAddClass('sidemenu-backdrop')
  .fromTo('opacity', 0.01, 0.4);

  let wrapperElem = createAnimation('modalEnter_wrapperElem').addElement(baseEl.querySelector('.modal-wrapper')) as any; //unknown as HTMLElement
  wrapperElem.elements[0].style.top = '0';

  let wrapperAnimation = createAnimation('modalEnter_wrapperAnimation')
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .beforeStyles({ 'opacity': 1 })
    .fromTo('transform', `translateX(-100%)`, 'translateX(-20%)');

  let baseAnimation = createAnimation('modalEnter_base')
    .addElement(baseEl)
    .duration(500)
    .easing('cubic-bezier(.36, .66, .1, 1)')
    .beforeAddClass('sidemenu')
    .addAnimation([backdropAnimation, wrapperAnimation]);
  return baseAnimation;
}

/*
* Role         : Leave Animation
* Parameters   : AnimationC <Animation>, baseEl <HTMLElement>
* Return 	     : Leave Animation Effect    
*/  
export function myLeaveAnimation(baseEl: HTMLElement):any {

  let backdropAnimation = createAnimation('modalLeave_backdrop')
    .addElement(baseEl.querySelector('ion-backdrop'))
    .fromTo('opacity', 0.3, 0.01);

  let wrapperElem = createAnimation('modalLeave_wrapperElem')
    .addElement(baseEl.querySelector('.modal-wrapper')) as any; //unknown as HTMLElement

  wrapperElem.elements[0].style.top = '0';
  let wrapperAnimation = createAnimation('modalLeave_wrapperAnimation')
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .beforeStyles({ 'opacity': 1 })
    .fromTo('transform', 'translateX(-20%)', `translateX(-100%)`);

  let baseAnimation = createAnimation('modalLeave_base')
    .easing('cubic-bezier(.36, .66, .1, 1)')
    .duration(400)
    .beforeAddClass('show-modal')
    .addAnimation([backdropAnimation, wrapperAnimation]);
  return baseAnimation;
}


/*
* Role         : Enter Toast Animation
* Parameters   : AnimationC <Animation>, baseEl <HTMLElement>
* Return 	     : Enter Animation Effect    
*/  
export function EntryToastAnimation(baseEl: HTMLElement): any  {

  let backdropAnimation = createAnimation('modalEnter_toast')
	.addElement(baseEl.querySelector('ion-toast'))
	.beforeAddClass('toast-action')
  .fromTo('opacity', 0.01, 0.4);

  let wrapperElem = createAnimation('modalEnter_wrapperElem').addElement(baseEl.querySelector('.toast-wrapper')) as any; //unknown as HTMLElement
  wrapperElem.elements[0].style.bottom = '0';

  let wrapperAnimation = createAnimation('modalEnter_wrapperAnimation')
    .addElement(baseEl.querySelector('.toast-wrapper'))
    .beforeStyles({ 'opacity': 1 })
    .fromTo('transform', `translateY(25%)`, 'translateY(0)');

  let baseAnimation = createAnimation('modalEnter_base')
    // .addElement(baseEl)
    .duration(400)
    .easing('cubic-bezier(.36, .66, .1, 1)')
    .beforeAddClass('sidemenu')
    .addAnimation([backdropAnimation, wrapperAnimation]);
  return baseAnimation;
}


/*
* Role         : Leave Toast Animation
* Parameters   : AnimationC <Animation>, baseEl <HTMLElement>
* Return 	     : Leave Animation Effect    
*/  
export function LeaveToastAnimation(baseEl: HTMLElement): any  {

  let backdropAnimation = createAnimation('modalLeave_toast')
    .addElement(baseEl.querySelector('ion-toast'))
    .fromTo('opacity', 0.3, 0.01);

  let wrapperElem = createAnimation('modalLeave_wrapperElem')
    .addElement(baseEl.querySelector('.toast-wrapper')) as any; //unknown as HTMLElement

  wrapperElem.elements[0].style.bottom = '0';
  let wrapperAnimation = createAnimation('modalLeave_wrapperAnimation')
    .addElement(baseEl.querySelector('.toast-wrapper'))
    .beforeStyles({ 'opacity': 1 })
    .fromTo('transform', 'translateY(0)', `translateY(25%)`);

  let baseAnimation = createAnimation('modalLeave_base')
    .easing('cubic-bezier(.36, .66, .1, 1)')
    .duration(300)
    .beforeAddClass('show-modal')
    .addAnimation([backdropAnimation, wrapperAnimation]);
  return baseAnimation;

}