import { Component } from '@angular/core';
import { NavController, MenuController, Platform,ModalController } from '@ionic/angular';
import { CommonserviceService } from './services/commonservice.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation, GeolocationOptions, Geoposition, PositionError } from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userData: any;
  selectedImage: any = '../assets/4.png';
  result: any;
  latitude
  longitude

  constructor(public commonService: CommonserviceService, public navCtrl: NavController, public menu: MenuController, 
    public platform : Platform,public androidPermissions : AndroidPermissions,public locationAccuracy : LocationAccuracy,
    public geolocation : Geolocation,
    public _modalPortal : ModalController) { 
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.backButtonListener();
    this.platform.ready().then(()=>{
      if (
        this.platform.is('ios')
        || this.platform.is('android')
        && !( this.platform.is('desktop')  ) ) 
      {      
      // this.checkGPSPermission();
      }
      // this.deeplink.route({
      
      // }).subscribe (match =>{
      //    alert(JSON.stringify(match))
      // },notmatch =>{
      //   alert('test'+JSON.stringify(notmatch))
      // })
    });
  }
  checkGPSPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
      result => {
        if (result.hasPermission) {
          //If having permission show 'Turn On GPS' dialogue
          this.askToTurnOnGPS();
        } else {
          //If not having permission ask for permission
          this.requestGPSPermission();
        }
      },
      err => {
        alert(err);
      }
    );
  }
  requestGPSPermission() {
   
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
          .then(
            () => {
              // call method to turn on GPS
              this.askToTurnOnGPS();
            },
            error => {
              //Show alert if user click on 'No Thanks'
              alert('requestPermission Error requesting location permissions ' + error)
            }
          );
  }
  askToTurnOnGPS() {
    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      () => {
        // When GPS Turned ON call method to get Accurate location coordinates
        //this.getLocationCoordinates();
      },
      error => alert('Error requesting location permissions ' + JSON.stringify(error))
    );
  }
  
  backButtonListener(): void {
    window.onpopstate = (evt) => {
        this._modalPortal.dismiss();
    }
  }
  addCash() {
    this.dissableBackevent();
    this.menu.close();
    this.navCtrl.navigateForward("/add-cash");
  }
  wallet() {
    this.menu.close();
    localStorage.setItem('tabValue','3')
    this.navCtrl.navigateForward("/my-account")
  }
  logout() {
    this.menu.close();
    this.commonService.logout();
  }
  more(){
    this.menu.close();
    this.navCtrl.navigateForward('/more');
  }
  menuOpened(){
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.commonService.commonApiService('get','profile','').then((result:any)=>{
      this.result = result;
      this.selectedImage = result.data.profile_image;
    });
  }
  profile(){
    this.menu.close();
    this.navCtrl.navigateForward('/personal-details');
  }
  
  dissableBackevent(){
    console.log(this.platform)
  }
  moreSetting(type)
  {
    this.menu.close();
    this.navCtrl.navigateForward('/moresettings?type=' + type);
  }

}
