import { Component, OnInit, Input } from "@angular/core";
import { NavController, ModalController } from "@ionic/angular";
import { CommonserviceService } from "../../services/commonservice.service";
import { LoadingService } from "../../services/loading.service";
import { ActivatedRoute } from "@angular/router";
import { NgxOtpInputConfig } from "ngx-otp-input";

@Component({
  selector: "app-otp-modal",
  templateUrl: "./otp-modal.component.html",
  styleUrls: ["./otp-modal.component.scss"],
})
export class OtpModalComponent implements OnInit {
  @Input() mobile_number: String;
  @Input() title: any;
  @Input() type: any;
  @Input() signupdata: any;
  otp: any;
  result: any;
  submitted: boolean = false;
  downloader: any;
  otpViaMobile: boolean = false;
  valueData: string = "";
  mask_moble: string = "";
  timerValue: any;
  constructor(
    public commonService: CommonserviceService,
    public navCtrl: NavController,
    public loader: LoadingService,
    public router: ActivatedRoute,
    public modalCtrl: ModalController
  ) {
    this. timer();
  }

  ngOnInit() {
    this.mask_moble = String(this.mobile_number).slice(0, -5) + "xxxxx";
    
  }

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class",
    },
  };

  handeOtpChange(value: string[]): void {
    console.log(value);
  }

  handleFillEvent(value: string): void {
    console.log(value);
    this.otp = value;
    console.log(this.otp);
  }

  
  signupApi() {
    console.log(this.signupdata);
    if (this.otp && this.type==1) {
      this.signupdata.otp = this.otp;
      this.signupdata.otp_flow = 1;
      console.log(this.signupdata.value);
      this.commonService
        .commonApiService("post", "signup", this.signupdata)
        .then((result: any) => {
          this.result = result;
          console.log("1-->", this.result);
          if (result.status == 200) {
            console.log(result.message);
            this.commonService.toastPreview(result.message);
            localStorage.setItem(
              "userData",
              JSON.stringify(this.result.userDetail)
            );
            localStorage.setItem("login_token", this.result.accessToken);
            this.close();
            this.navCtrl.navigateRoot('/dashboard');
          } else {
            console.log(result.message);
            this.commonService.toastPreview(result.message);
          }
        });
    } else if(this.otp && this.type==2){
      // this.close(this.otp);
      let data = {mobile:this.mobile_number,otp_type : 2, otp: this.otp,otp_flow : 1};
      this.commonService
        .commonApiService("post", "otpverfication", data)
        .then((result: any) => {
          this.result = result;
          console.log("1-->", this.result);
          if (result.status == 200) {
            console.log(result.message);
            this.commonService.toastPreview(result.message);           
            this.close(this.otp);
          } else {
            console.log(result.message);
            this.commonService.toastPreview(result.message);
          }
        });
    } else {
      this.commonService.toastPreview("Enter OTP");
    }
  }

  timer() {
    var timeleft = 0;
    this.downloader = setInterval(() => {
      console.log(timeleft);
      if (timeleft >= 30) {
        console.log(this.downloader);
        this.otpViaMobile = true;
        clearInterval(this.downloader);
      }
      this.timerValue = "00 : " + (30 - timeleft);
      if (timeleft > 20) {
        this.timerValue = "00 : 0" + (30 - timeleft);
      }
      document.getElementById("timer").innerHTML = this.timerValue;
      timeleft += 1;
    }, 1000);
  }

  resendOtp() {
    this.otpViaMobile = false;
    this.timer();
    let data = { mobile: this.mobile_number, otp_type : this.type };
    console.log(data);
    this.loader.presentLoading();
    this.commonService
      .commonApiService("post", "resendOTP", data)
      .then((result: any) => {
        this.result = result;
        this.loader.dismissLoading();
        if (result.status == 200) {
          this.commonService.toastPreview(result.message);
        } else {
          console.log("error", result.message);
          this.commonService.toastPreview(result.message);
        }
      });
  }

  // otpmailphone(saveType) {
  //   console.log(this.otp)
  //   let data = {};
  //   if (saveType == 1) {
  //     data = { 'email_otp': this.otp };
  //   } else {
  //     data = { 'mobile_otp': this.otp };
  //   }
  //   console.log(data)
  //   this.commonService.commonApiService('post', 'updatePhoneEmail', data).then((result: any) => {
  //     if (result.status == 200) {
  //       console.log("success", result.message);
  //       this.commonService.toastPreview(result.message);
  //       this.navCtrl.navigateBack('/personal-details');
  //     }
  //     else {
  //       console.log("error", result.message);
  //     }
  //   })
  // }

  ionViewWillLeave() {
    clearInterval(this.downloader);
  }
  close(value: any = "") {
    clearInterval(this.downloader);
    this.modalCtrl.dismiss(value);
  }
}
