import { Component, OnInit,HostListener } from '@angular/core';
import { NavController, MenuController,ModalController } from '@ionic/angular';
import { Config } from 'src/app/config/config';
import { CommonserviceService } from '../../services/commonservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
varSelectValue:string='1';
walletName:string='';
objTitle={
  '1':'',
  '2':'My Contests',
  '3':'Wallet',
  '4':'Profile'
}
deviceWidth : any;
@HostListener('window:resize', ['$event']) getScreenSize(event?) {
  this.deviceWidth = window.innerWidth;
  console.log(this.deviceWidth);
}
  constructor(public objNavCtrll : NavController, public objModalCtrl:ModalController, private commonservice: CommonserviceService, public conf:Config) { }

  ngOnInit() {
    if(localStorage.getItem('tabValue')){
    this.varSelectValue = localStorage.getItem('tabValue');
    console.log(this.varSelectValue)
  }
  if(localStorage.getItem('footer')){
    this.walletName = this.objTitle[localStorage.getItem('footer')]
  }
  }
  

  opendata(value:string, page:string){    
    this.objNavCtrll.navigateForward('/'+page)
    localStorage.setItem('tabValue',value)
  }

  ionViewWillEnter(){
    this.varSelectValue = localStorage.getItem('tabValue');
    this.walletName = this.objTitle[localStorage.getItem('footer')]
  }

  async openMenu() {
    this.commonservice.openMenu();
  }
}
