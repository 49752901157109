import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '../app/guard/login.guard';
import { MenuComponent } from './components/menu/menu.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
    {
    path: 'otp-verification',
    loadChildren: () => import('./pages/otp-verification/otp-verification.module').then( m => m.OtpVerificationPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'tab',
    loadChildren: () => import('./pages/tab/tab.module').then( m => m.TabPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),canActivate:[LoginGuard]
  },
  {
    path: 'matches',
    loadChildren: () => import('./pages/matches/matches.module').then( m => m.MatchesPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'more',
    loadChildren: () => import('./pages/more/more.module').then( m => m.MorePageModule),canActivate:[LoginGuard]
  },
  {
    path: 'profile-details',
    loadChildren: () => import('./pages/profile-details/profile-details.module').then( m => m.ProfileDetailsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'bank-details',
    loadChildren: () => import('./pages/bank-details/bank-details.module').then( m => m.BankDetailsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'pan-details',
    loadChildren: () => import('./pages/pan-details/pan-details.module').then( m => m.PanDetailsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/verify/verify.module').then( m => m.VerifyPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'select-team',
    loadChildren: () => import('./pages/select-team/select-team.module').then( m => m.SelectTeamPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'team-preview',
    loadChildren: () => import('./pages/team-preview/team-preview.module').then( m => m.TeamPreviewPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'contest',
    loadChildren: () => import('./pages/contest/contest.module').then( m => m.ContestPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'prize-details',
    loadChildren: () => import('./pages/prize-details/prize-details.module').then( m => m.PrizeDetailsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'add-cash',
    loadChildren: () => import('./pages/add-cash/add-cash.module').then( m => m.AddCashPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'selected-players-list',
    loadChildren: () => import('./pages/selected-players-list/selected-players-list.module').then( m => m.SelectedPlayersListPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'recent-transactions',
    loadChildren: () => import('./pages/recent-transactions/recent-transactions.module').then( m => m.RecentTransactionsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'select-team-contest',
    loadChildren: () => import('./pages/select-team-contest/select-team-contest.module').then( m => m.SelectTeamContestPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'joined-contest',
    loadChildren: () => import('./pages/joined-contest/joined-contest.module').then( m => m.JoinedContestPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./pages/withdraw/withdraw.module').then( m => m.WithdrawPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'personal-details',
    loadChildren: () => import('./pages/personal-details/personal-details.module').then( m => m.PersonalDetailsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'my-teams',
    loadChildren: () => import('./pages/my-teams/my-teams.module').then( m => m.MyTeamsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'moresettings',
    loadChildren: () => import('./pages/moresettings/moresettings.module').then( m => m.MoresettingsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'players-point-modal',
    loadChildren: () => import('./pages/players-point-modal/players-point-modal.module').then( m => m.PlayersPointModalPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'scorecard',
    loadChildren: () => import('./pages/scorecard/scorecard.module').then( m => m.ScorecardPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then( m => m.MaintenancePageModule),canActivate:[LoginGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),canActivate:[LoginGuard]
  },
  {
    path: 'story',
    loadChildren: () => import('./pages/story/story.module').then( m => m.StoryPageModule)
  },
  {
    path:'menupage',component:MenuComponent,canActivate:[LoginGuard]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
